//  =================
//      Imports
//  =================

@import '../../base/base';    // Base Variables


/*----------Theme checkbox---------*/

.new-control {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-left: 1.5rem;
  margin-right: 1rem;
  font-weight: 100;
  font-size: 14px;
}

.new-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.new-control.new-checkbox {
  .new-control-indicator {
    position: absolute;
    top: 2px;
    left: 0;
    display: block;
    width: 17px;
    height: 17px;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: $m-color_3;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    border-radius: 4px;
  }

  cursor: pointer;

  > input:checked ~ span.new-control-indicator {
    background: $m-color_6;

    &:after {
      display: block;
    }
  }

  span.new-control-indicator:after {
    border: solid $white;
    top: 50%;
    left: 50%;
    margin-left: -2px;
    margin-top: -5px;
    width: 4px;
    height: 8px;
    border-width: 0 2px 2px 0 !important;
    transform: rotate(45deg);
    content: '';
    position: absolute;
    display: none;
    margin-left: -2px;
    margin-top: -5px;
    width: 4px;
    height: 8px;
    height: 8px;
  }
}

/*rounded checkbox*/

.new-checkbox-rounded span.new-control-indicator {
  border-radius: 50% !important;
}

/*  line through colors   */

.new-control {
  &.new-checkbox {
    &.new-checkbox-text {
      > input:checked ~ span.new-chk-content, &.checkbox-outline-default > input:checked ~ span.new-chk-content {
        color: $m-color_6;
      }

      &.checkbox-primary > input:checked ~ span.new-chk-content, &.checkbox-outline-primary > input:checked ~ span.new-chk-content {
        color: $primary;
      }

      &.checkbox-success > input:checked ~ span.new-chk-content, &.checkbox-outline-success > input:checked ~ span.new-chk-content {
        color: $success;
      }

      &.checkbox-info > input:checked ~ span.new-chk-content, &.checkbox-outline-info > input:checked ~ span.new-chk-content {
        color: $info;
      }

      &.checkbox-warning > input:checked ~ span.new-chk-content, &.checkbox-outline-warning > input:checked ~ span.new-chk-content {
        color: $warning;
      }

      &.checkbox-danger > input:checked ~ span.new-chk-content, &.checkbox-outline-danger > input:checked ~ span.new-chk-content {
        color: $danger;
      }

      &.checkbox-secondary > input:checked ~ span.new-chk-content, &.checkbox-outline-secondary > input:checked ~ span.new-chk-content {
        color: $secondary;
      }

      &.checkbox-dark > input:checked ~ span.new-chk-content, &.checkbox-outline-dark > input:checked ~ span.new-chk-content {
        color: $dark;
      }
    }

    &.checkbox-primary > input:checked ~ span.new-control-indicator {
      background: $primary;
    }

    &.checkbox-success > input:checked ~ span.new-control-indicator {
      background: $success;
    }

    &.checkbox-info > input:checked ~ span.new-control-indicator {
      background: $info;
    }

    &.checkbox-warning > input:checked ~ span.new-control-indicator {
      background: $warning;
    }

    &.checkbox-danger > input:checked ~ span.new-control-indicator {
      background: $danger;
    }

    &.checkbox-secondary > input:checked ~ span.new-control-indicator {
      background: $secondary;
    }

    &.checkbox-dark > input:checked ~ span.new-control-indicator {
      background: $dark;
    }

    &[class*="checkbox-outline-"] > input:checked ~ span.new-control-indicator {
      background-color: transparent;
    }

    &.checkbox-outline-default > input:checked ~ span.new-control-indicator {
      border: 2px solid $m-color_6;
    }

    &.checkbox-outline-primary > input:checked ~ span.new-control-indicator {
      border: 2px solid $primary;
    }

    &.checkbox-outline-success > input:checked ~ span.new-control-indicator {
      border: 2px solid $success;
    }

    &.checkbox-outline-info > input:checked ~ span.new-control-indicator {
      border: 2px solid $info;
    }

    &.checkbox-outline-warning > input:checked ~ span.new-control-indicator {
      border: 2px solid $warning;
    }

    &.checkbox-outline-danger > input:checked ~ span.new-control-indicator {
      border: 2px solid $danger;
    }

    &.checkbox-outline-secondary > input:checked ~ span.new-control-indicator {
      border: 2px solid $secondary;
    }

    &.checkbox-outline-dark > input:checked ~ span.new-control-indicator {
      border: 2px solid $dark;
    }

    &.checkbox-outline-default > input:checked ~ span.new-control-indicator:after {
      border-color: $m-color_6;
    }

    &.checkbox-outline-primary > input:checked ~ span.new-control-indicator:after {
      border-color: $primary;
    }

    &.checkbox-outline-success > input:checked ~ span.new-control-indicator:after {
      border-color: $success;
    }

    &.checkbox-outline-info > input:checked ~ span.new-control-indicator:after {
      border-color: $info;
    }

    &.checkbox-outline-warning > input:checked ~ span.new-control-indicator:after {
      border-color: $warning;
    }

    &.checkbox-outline-danger > input:checked ~ span.new-control-indicator:after {
      border-color: $danger;
    }

    &.checkbox-outline-secondary > input:checked ~ span.new-control-indicator:after {
      border-color: $secondary;
    }

    &.checkbox-outline-dark > input:checked ~ span.new-control-indicator:after {
      border-color: $dark;
    }
  }

  &.new-radio {
    .new-control-indicator {
      position: absolute;
      top: 2px;
      left: 0;
      display: block;
      width: 16px;
      height: 16px;
      pointer-events: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: $m-color_3;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
      border-radius: 50%;
    }

    cursor: pointer;

    > input:checked ~ span.new-control-indicator {
      background: $m-color_6;
    }

    span.new-control-indicator:after {
      top: 50%;
      left: 50%;
      margin-left: -3px;
      margin-top: -3px;
      content: '';
      position: absolute;
      display: none;
      border-radius: 50%;
      height: 6px;
      width: 6px;
      background-color: $white;
    }

    &.square-radio {
      .new-control-indicator, span.new-control-indicator:after {
        border-radius: 0;
      }
    }

    &.radio-primary > input:checked ~ span.new-control-indicator {
      background: $primary;
    }

    &.radio-success > input:checked ~ span.new-control-indicator {
      background: $success;
    }

    &.radio-info > input:checked ~ span.new-control-indicator {
      background: $info;
    }

    &.radio-warning > input:checked ~ span.new-control-indicator {
      background: $warning;
    }

    &.radio-danger > input:checked ~ span.new-control-indicator {
      background: $danger;
    }

    &.radio-secondary > input:checked ~ span.new-control-indicator {
      background: $secondary;
    }

    &.radio-dark > input:checked ~ span.new-control-indicator {
      background: $dark;
    }

    &[class*="radio-classic-"] > input:checked ~ span.new-control-indicator {
      background-color: transparent;
    }

    &.radio-classic-default > input:checked ~ span.new-control-indicator {
      border: 3px solid $m-color_6;
    }

    &.radio-classic-primary > input:checked ~ span.new-control-indicator {
      border: 3px solid $primary;
    }

    &.radio-classic-success > input:checked ~ span.new-control-indicator {
      border: 3px solid $success;
    }

    &.radio-classic-info > input:checked ~ span.new-control-indicator {
      border: 3px solid $info;
    }

    &.radio-classic-warning > input:checked ~ span.new-control-indicator {
      border: 3px solid $warning;
    }

    &.radio-classic-danger > input:checked ~ span.new-control-indicator {
      border: 3px solid $danger;
    }

    &.radio-classic-secondary > input:checked ~ span.new-control-indicator {
      border: 3px solid $secondary;
    }

    &.radio-classic-dark > input:checked ~ span.new-control-indicator {
      border: 3px solid $dark;
    }

    &.radio-classic-default > input:checked ~ span.new-control-indicator:after {
      background-color: $m-color_6;
    }

    &.radio-classic-primary > input:checked ~ span.new-control-indicator:after {
      background-color: $primary;
    }

    &.radio-classic-success > input:checked ~ span.new-control-indicator:after {
      background-color: $success;
    }

    &.radio-classic-info > input:checked ~ span.new-control-indicator:after {
      background-color: $info;
    }

    &.radio-classic-warning > input:checked ~ span.new-control-indicator:after {
      background-color: $warning;
    }

    &.radio-classic-danger > input:checked ~ span.new-control-indicator:after {
      background-color: $danger;
    }

    &.radio-classic-secondary > input:checked ~ span.new-control-indicator:after {
      background-color: $secondary;
    }

    &.radio-classic-dark > input:checked ~ span.new-control-indicator:after {
      background-color: $dark;
    }

    &.new-radio-text {
      > input:checked ~ span.new-radio-content, &.radio-classic-default > input:checked ~ span.new-radio-content {
        color: $m-color_6;
      }

      &.radio-primary > input:checked ~ span.new-radio-content, &.radio-classic-primary > input:checked ~ span.new-radio-content {
        color: $primary;
      }

      &.radio-success > input:checked ~ span.new-radio-content, &.radio-classic-success > input:checked ~ span.new-radio-content {
        color: $success;
      }

      &.radio-info > input:checked ~ span.new-radio-content, &.radio-classic-info > input:checked ~ span.new-radio-content {
        color: $info;
      }

      &.radio-warning > input:checked ~ span.new-radio-content, &.radio-classic-warning > input:checked ~ span.new-radio-content {
        color: $warning;
      }

      &.radio-danger > input:checked ~ span.new-radio-content, &.radio-classic-danger > input:checked ~ span.new-radio-content {
        color: $danger;
      }

      &.radio-secondary > input:checked ~ span.new-radio-content, &.radio-classic-secondary > input:checked ~ span.new-radio-content {
        color: $secondary;
      }

      &.radio-dark > input:checked ~ span.new-radio-content, &.radio-classic-dark > input:checked ~ span.new-radio-content {
        color: $dark;
      }
    }
  }
}

/*  Color   */

/*   Outline checkbox   */

/*----------Theme Radio---------*/

/*square radio*/

/*  Color   */

/*   Outline Radio   */

/*  line through colors   */